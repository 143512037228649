import { takeLatest, all } from "redux-saga/effects";
import API from "../../services/Api";
import ApiSample from "../../services/ApiSample";

/* ------------- Types ------------- */

import { TemplateTypes } from "../reducers/TemplateRedux"; 
import { AuthTypes } from "../reducers/AuthRedux";
import { CommentsTypes } from "../reducers/CommentsRedux";
import { HistoryMessageTypes } from "../reducers/HistoryMessageRedux";
import { MessageTypes } from "../reducers/MessageRedux";
 
/* ------------- Sagas ------------- */

import { templateRequest } from "./TemplateSagas";  
import {
  loginUserRequest,
  signinUser,
  userSignOut,
  authForgotPasswordRequest,
  authResetPasswordRequest,
  authChangePasswordRequest,
  getHistoryMessage
} from "./AuthSagas";
import { getRequest } from "./CommentsSagas";
import { getHistoryMessageRequest } from "./HistoryMessageSagas";
import { getMessageRequest } from "./MessageSagas";

/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.
const api = API.create();
const apisample = ApiSample.create();

/* ------------- Connect Types To Sagas ------------- */

export default function* root() {
  // console.log('ROOT sagas JALAN !')
  yield all([
    takeLatest(AuthTypes.GET_HISTORY_MESSAGE, getHistoryMessage, api),
    takeLatest(AuthTypes.LOGIN_USER_REQUEST, loginUserRequest, api),
    takeLatest(TemplateTypes.TEMPLATE_REQUEST, templateRequest, api),
    takeLatest(HistoryMessageTypes.GET_HISTORY_MESSAGE_REQUEST, getHistoryMessageRequest, api),
    takeLatest(MessageTypes.GET_MESSAGE_REQUEST, getMessageRequest, api),
    takeLatest(AuthTypes.SIGNIN_USER, signinUser, api),
    takeLatest(AuthTypes.USER_SIGN_OUT, userSignOut, api),
    takeLatest(
      AuthTypes.AUTH_FORGOT_PASSWORD_REQUEST,
      authForgotPasswordRequest,
      api
    ),
    takeLatest(
      AuthTypes.AUTH_RESET_PASSWORD_REQUEST,
      authResetPasswordRequest,
      api
    ),
    takeLatest(
      AuthTypes.AUTH_CHANGE_PASSWORD_REQUEST,
      authChangePasswordRequest,
      api
    ),
     
    //Comment
    takeLatest(CommentsTypes.GET_REQUEST, getRequest, api), 
    
  ]);
}
